.ref-form-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center aligns everything in the container */
  }
  
  .ref-title {
    color: #333;
    margin-bottom: 30px;
  }
  
  .ref-description {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #555;
    /* No need for text-align here since it's inherited from .ref-form-container */
  }
  
  /* Style your link to look like a button */
  .submit-btn {
    text-decoration: none; /* Removes underline from the link */
    display: inline-block; /* Allows width and centering for the 'button' */
    width: auto; /* Adjust width as needed or use a specific value */
    padding: 15px 30px; /* Adjust padding as needed */
    margin-bottom: 20px; /* Space below the button */
    background-color: #0044cc;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  /* Add hover effect for the link/button */
  .submit-btn:hover {
    background-color: #003399;
  }
  
  .back-dashboard {
    text-decoration: none; /* Removes underline from the link */
    display: inline-block; /* Allows width and centering for the 'button' */
    width: auto; /* Adjust width as needed or use a specific value */
    padding: 15px 30px; /* Adjust padding as needed */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Keep the button full width on mobile view */
    margin: 20px auto; /* Centers the button in the container */
  }
  
  /* Adjust the hover effect for the back-dashboard button as well */
  .back-dashboard:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 600px) {
    .ref-form-container {
      padding: 10px;
    }
  }
  