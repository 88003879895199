.timeslot-delete {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .timeslot-delete h1 {
    text-align: center;
  }
  
  .timeslot-list {
    margin: 20px 0;
    padding: 0;
    list-style: none;
  }
  
  .timeslot-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
  }
  
  .delete-button, .delete-all {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .delete-button:hover, .delete-all:hover {
    background-color: #ff7875;
  }
  
  .delete-all {
    background-color: #faad14;
  }
  
  .delete-all:hover {
    background-color: #ffc069;
  }
  