.rules-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .rule {
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .back-dashboard {
    display: block;
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  @media (max-width: 600px) {
    .rules-container {
      padding: 10px;
    }
  }
  