/* PrivacyPolicy.css */

/* Base mobile-first styles */
.privacy-policy-container {
    background-color: #fff; /* Background color for the container */
    color: #333; /* Text color */
    font-family: 'Arial', sans-serif; /* Common font for readability */
    padding: 15px; /* Padding around the content */
    line-height: 1.6; /* Line height for better readability */
  }
  
  .privacy-policy-container h1,
  .privacy-policy-container h2 {
    text-align: center; /* Center headings */
  }
  
  .privacy-policy-container h1 {
    margin-bottom: 20px; /* Space after the main heading */
  }
  
  .privacy-policy-container h2 {
    color: #444; /* A slightly darker color for subheadings */
    margin-top: 30px; /* Space above subheadings */
    margin-bottom: 15px; /* Space below subheadings */
  }
  
  .privacy-policy-container p,
  .privacy-policy-container ul {
    margin-bottom: 15px; /* Space below paragraphs and lists */
  }
  
  .privacy-policy-container a {
    color: #1a0dab; /* Color for links */
    text-decoration: none; /* Optionally remove underline from links */
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline; /* Underline links on hover for better UX */
  }
  
  /* Adjustments for larger screens with a min-width of 600px */
  @media (min-width: 600px) {
    .privacy-policy-container {
      padding: 20px; /* More padding on larger screens */
      max-width: 600px; /* Max width to prevent lines from being too long */
      margin: 0 auto; /* Center the container */
    }
  
    .privacy-policy-container h2 {
      text-align: left; /* Align subheadings to the left on larger screens */
    }
  }
  