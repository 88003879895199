.upcoming-matches {
 
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .upcoming-matches h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
  }
  
  .gym {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .gym:hover {
    transform: translateY(-5px);
  }
  
  .gym h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .slot {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 4px;
  }
  
  .slot p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #555;
  }
  
  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .time span {
    font-size: 16px;
    color: #333;
  }
  
  .reserve-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reserve-button:hover {
    background-color: #0056b3;
  }
  
  .reserve-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }