.sign-up-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sign-up-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

.gym-select-container,
.date-select-container {
  margin-bottom: 30px;
}

.select-label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #555;
}

.select-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  transition: border-color 0.3s;
}

.select-input:focus {
  outline: none;
  border-color: #007bff;
}

.slots-container {
  margin-top: 30px;
}

.loading-message,
.no-slots-message {
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  color: #888;
}

.slots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.time-slot {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s;
}

.time-slot:hover {
  transform: translateY(-5px);
}

.slot-info {
  margin-bottom: 20px;
}

.slot-time {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.slot-availability {
  font-size: 16px;
  color: #888;
}

.reserve-button {
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button {
  display: block;
  margin: 0 auto 30px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #f8f8f8;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #e0e0e0;
}

.reserve-button:hover {
  background-color: #0056b3;
}

.reserve-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}