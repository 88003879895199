/* AdminPanel.css */
.admin-panel {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
 
  }
  
  .admin-panel h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .user-list {
    margin-bottom: 20px;
  }
  
  .user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .user-item p {
    margin: 0;
    font-size: 18px;
  }
  
  .user-item button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .user-item button.delete {
    background-color: #ff4d4d;
    color: white;
  }
  
  .user-item button.edit {
    background-color: #0429cc;
    color: white;
  }
  
  .edit-form {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .edit-form input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-form button {
    margin-top: 10px;
    padding: 8px 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #2196F3;
    color: white;
    cursor: pointer;
  }