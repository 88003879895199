.match-history {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .match-history h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .match {
    background-color: #f4f4f4;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .match-details {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .weight-input {
    margin-bottom: 10px;
  }
  
  .weight-input label {
    display: block;
    margin-bottom: 5px;
  }
  
  .weight-input input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .winner-select {
    margin-bottom: 10px;
  }
  
  .winner-select label {
    display: block;
    margin-bottom: 5px;
  }
  
  .record-result-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .record-result-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .back-dashboard-button {
    display: block;
    width: fit-content;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #2196f3;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
  }