.faq-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .faq {
    background: #f9f9f9;
    margin: 10px 0;
    padding: 10px;
    cursor: pointer;
  }
  
  .faq.open .faq-answer {
    margin-top: 10px;
  }
  
  .faq-question {
    font-weight: bold;
  }
  
  .faq-answer {
    display: none;
  }
  
  .faq.open .faq-answer {
    display: block;
  }
  
  .back-dashboard {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  @media (max-width: 600px) {
    .faq-container {
      padding: 10px;
    }
  }
  