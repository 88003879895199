/* AddTimeSlots.css */
.add-timeslots-container {
 
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.react-calendar__tile--now {
  background-color: #ff0000;
  color: #fff;
  font-weight: bold;
}
.time-slot-button.selected {
  background-color: #4CAF50; /* or any color that indicates selection */
  color: white;
}

.react-calendar__tile--pastDay {
  background-color: #e0e0e0;
  color: #888;
  pointer-events: none;
}

.react-calendar__tile:not(.react-calendar__tile--pastDay):hover {
  background-color: #f0f0f0;
}

.back-button:hover {
  background-color: #0056b3;
}

h1 {
  font-size: 28px;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.calendar-header {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-header label {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.calendar-header select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.calendar-wrapper {
  margin-bottom: 20px;
  width: 100%;
}

.calendar {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.react-calendar__navigation__label {
  font-size: 18px;
  font-weight: bold;
}

.react-calendar__navigation__arrow {
  font-size: 24px;
  color: #333;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 10px;
}

.react-calendar__month-view__weekdays {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.react-calendar__month-view__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.react-calendar__tile {
  padding: 8px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.time-slots-wrapper {
  margin-top: 30px;
  width: 100%;
}

.time-slots-wrapper h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.time-slots {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
  justify-items: center;
}

.time-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.time-slot span {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.time-slot input {
  width: 60px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.save-button-container {
  margin-top: 20px;
  text-align: center;
}

.save-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .add-timeslots-container {
    padding: 15px;
  }

  h1 {
    font-size: 24px;
  }

  .calendar-header {
    align-items: flex-start;
  }

  .calendar {
    font-size: 14px;
  }

  .react-calendar__tile {
    padding: 6px;
  }

  .time-slots {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}